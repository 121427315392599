define("discourse/plugins/discourse-zoom/discourse/components/webinar", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "jquery", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/plugins/discourse-zoom/discourse/components/modal/edit-webinar", "discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers"], function (_exports, _component, _object, _computed, _runloop, _service, _jquery, _ajax, _decorators, _editWebinar, _webinarHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PENDING = "pending",
    ENDED = "ended",
    STARTED = "started";
  class Webinar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    loading = false;
    topic = null;
    webinar = null;
    webinarId = null;
    showTimer = false;
    static #_2 = (() => dt7948.g(this.prototype, "canEdit", [(0, _computed.alias)("topic.details.can_edit")]))();
    #canEdit = (() => (dt7948.i(this, "canEdit"), void 0))();
    showingRecording = false;
    static #_3 = (() => dt7948.g(this.prototype, "hostDisplayName", [(0, _computed.or)("webinar.host.name", "webinar.host.username")]))();
    #hostDisplayName = (() => (dt7948.i(this, "hostDisplayName"), void 0))();
    init() {
      super.init(...arguments);
      this.fetchDetails();
    }
    webinarEnded(webinar) {
      if (webinar.status === ENDED || moment(webinar.ends_at).isBefore(moment())) {
        return true;
      }
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "webinarEnded", [(0, _decorators.default)("webinar.{status,ends_at}")]))();
    webinarStarted(status) {
      return status === STARTED;
    }
    static #_5 = (() => dt7948.n(this.prototype, "webinarStarted", [(0, _decorators.default)("webinar.status")]))();
    fetchDetails() {
      if (!this.webinarId) {
        return;
      }
      this.set("loading", true);
      this.store.find("webinar", this.webinarId).then(results => {
        this.setProperties({
          loading: false,
          webinar: results
        });
        this.messageBus.subscribe(this.messageBusEndpoint, data => {
          this.webinar.set("status", data.status);
        });
        this.appEvents.trigger("discourse-zoom:webinar-loaded");
      }).catch(() => {
        this.set("loading", false);
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this.webinar) {
        this.messageBus.unsubscribe(this.messageBusEndpoint);
      }
      clearInterval(this.interval);
    }
    setupTimer(webinar, starts_at, duration, status) {
      if (status !== PENDING) {
        return false;
      }
      const startsAtMoment = moment(starts_at);
      this.interval = setInterval(interval => this.updateTimer(startsAtMoment, interval), 1000);
      this.updateTimer(startsAtMoment);
      return true;
    }
    static #_6 = (() => dt7948.n(this.prototype, "setupTimer", [(0, _decorators.default)("webinar", "webinar.starts_at", "webinar.duration", "webinar.status")]))();
    updateTimer(starts_at, interval) {
      const duration = moment.duration(starts_at.diff(moment()));
      this.set("cSecs", duration.seconds());
      this.set("cMins", duration.minutes());
      this.set("cHours", duration.hours());
      this.set("cDays", parseInt(duration.asDays(), 10));
      if (starts_at.isBefore(moment())) {
        this.set("showTimer", false);
        if (interval) {
          clearInterval(interval);
        }
      } else {
        this.set("showTimer", true);
      }
    }
    messageBusEndpoint(webinar) {
      return `/zoom/webinars/${webinar.id}`;
    }
    static #_7 = (() => dt7948.n(this.prototype, "messageBusEndpoint", [(0, _decorators.default)("webinar")]))();
    displayAttendees() {
      return this.siteSettings.zoom_display_attendees;
    }
    static #_8 = (() => dt7948.n(this.prototype, "displayAttendees", [_decorators.default]))();
    schedule(webinar) {
      if (webinar.ends_at === null) {
        return moment(webinar.starts_at).format("Do MMMM, Y");
      }
      return (0, _webinarHelpers.formattedSchedule)(webinar.starts_at, webinar.ends_at);
    }
    static #_9 = (() => dt7948.n(this.prototype, "schedule", [(0, _decorators.default)("webinar.{starts_at,ends_at}")]))();
    editPanelists() {
      this.modal.show(_editWebinar.default, {
        model: {
          webinar: this.webinar,
          setWebinar: value => this.set("webinar", value),
          setTitle: value => this.webinar.set("title", value),
          setStartsAt: value => this.webinar.set("starts_at", value),
          setVideoUrl: value => this.webinar.set("video_url", value)
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "editPanelists", [_object.action]))();
    showRecording() {
      this.set("showingRecording", true);
      (0, _runloop.next)(() => {
        const $videoEl = (0, _jquery.default)(".video-recording");
        window.scrollTo({
          top: $videoEl.offset().top - 60,
          behavior: "smooth"
        });
        (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}/watch.json`, {
          type: "PUT"
        });
      });
    }
    static #_11 = (() => dt7948.n(this.prototype, "showRecording", [_object.action]))();
  }
  _exports.default = Webinar;
});