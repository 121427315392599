define("discourse/plugins/discourse-zoom/discourse/routes/user-activity-webinars", ["exports", "discourse/routes/user-topic-list"], function (_exports, _userTopicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityWebinars extends _userTopicList.default {
    userActionType = null;
    noContentHelpKey = "zoom.no_user_webinars";
    model() {
      return this.store.findFiltered("topicList", {
        filter: `topics/webinar-registrations/${this.modelFor("user").get("username_lower")}`
      });
    }
  }
  _exports.default = UserActivityWebinars;
});