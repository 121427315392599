define("discourse/plugins/discourse-zoom/discourse/initializers/zoom-composer", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _composer, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "zoom-composer",
    initialize() {
      _composer.default.serializeOnCreate("zoom_id", "zoomId");
      _composer.default.serializeOnCreate("zoom_webinar_title", "zoomWebinarTitle");
      _composer.default.serializeOnCreate("zoom_webinar_start_date", "zoomWebinarStartDate");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.decorateWidget("post:before", dec => {
          if (dec.canConnectComponent && dec.attrs.firstPost) {
            if (!dec.attrs.cloaked) {
              return dec.connect({
                component: "post-top-webinar",
                context: "model"
              });
            }
          }
        });
        api.modifyClass("controller:topic", dt7948.p({
          pluginId: "discourse-zoom",
          _addWebinarClass() {
            const webinar = this.get("model.webinar");
            if (webinar) {
              document.body.classList.add("has-webinar");
            } else {
              document.body.classList.remove("has-webinar");
            }
          }
        }, [["method", "_addWebinarClass", [(0, _decorators.observes)("model.postStream.loaded")]]]));
      });
    }
  };
});