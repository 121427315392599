define("discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.zoomId}}
    <div class="composer-webinar">
      <span class="webinar-label">Webinar - </span>
  
      <span class="webinar-title">
        {{this.model.zoomWebinarTitle}}
      </span>
  
      <DButton
        class="cancel no-text"
        @action={{action "removeWebinar"}}
        @icon="xmark"
        @title="zoom.remove"
      />
    </div>
  {{/if}}
  */
  {
    "id": "aF9TNmB2",
    "block": "[[[41,[30,0,[\"model\",\"zoomId\"]],[[[1,\"  \"],[10,0],[14,0,\"composer-webinar\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"webinar-label\"],[12],[1,\"Webinar - \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"webinar-title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"zoomWebinarTitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"cancel no-text\"]],[[\"@action\",\"@icon\",\"@title\"],[[28,[37,2],[[30,0],\"removeWebinar\"],null],\"xmark\",\"zoom.remove\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-zoom/discourse/templates/components/remove-webinar-from-composer.hbs",
    "isStrictMode": false
  });
});