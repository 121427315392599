define("discourse/plugins/discourse-zoom/discourse/components/webinar-option-row", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-zoom/discourse/lib/webinar-helpers"], function (_exports, _component, _object, _decorators, _webinarHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WebinarOptionRow extends _component.default {
    model = null;
    onSelect = null;
    init() {
      super.init(...arguments);
      this.onSelect = this.onSelect || (() => {});
    }
    schedule(model) {
      return (0, _webinarHelpers.formattedSchedule)(model.start_time, moment(model.start_time).add(model.duration, "m").toDate());
    }
    static #_ = (() => dt7948.n(this.prototype, "schedule", [(0, _decorators.default)("model")]))();
    selectWebinar() {
      this.onSelect();
    }
    static #_2 = (() => dt7948.n(this.prototype, "selectWebinar", [_object.action]))();
  }
  _exports.default = WebinarOptionRow;
});