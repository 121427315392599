define("discourse/plugins/discourse-zoom/discourse/components/remove-webinar-from-composer", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RemoveWebinarFromComposer extends _component.default {
    model = null;
    removeWebinar() {
      this.model.set("zoomId", null);
    }
    static #_ = (() => dt7948.n(this.prototype, "removeWebinar", [_object.action]))();
  }
  _exports.default = RemoveWebinarFromComposer;
});